const filterAll = (ticket, search) => {
    return ticket.filter((item) => {
        return Object.keys(item).some((key) => {
            return item[key]
                .toString()
                .toLowerCase()
                .includes(search.toLowerCase());
        });
    });
};

export default filterAll;
