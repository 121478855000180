import config from "../../main-config.json";
const URL = config.failures_api_url;
const LOGIN_ENDPOINT = URL + "/auth/login.php";

const login = async (data) => {
    let headersList = {
        Accept: "*/*",
    };

    let bodyContent = new FormData();
    bodyContent.append("username", data.username);
    bodyContent.append("password", data.password);

    try {
        let response = await fetch(LOGIN_ENDPOINT, {
            method: "POST",
            body: bodyContent,
            headers: headersList,
        }).then((res) => res.json());

        if (
            response.status === 200 &&
            response.data.jwt &&
            response.data.expireAt
        ) {
            let jwt = response.data.jwt;
            let expire_at = response.data.expireAt;
            localStorage.setItem("access_token", jwt);
            localStorage.setItem("expire_at", expire_at);

            return response.data;
        } else {
            return response;
        }
    } catch (e) {
        console.log(e);
    }
};

const logout = () => {
    localStorage.removeItem("access_token");
    localStorage.setItem("iA", "false");
    localStorage.removeItem("expire_at");
};

export { login, logout };
