import { useState, useEffect } from "react";
import withRouter from "../utils/withRouter";
import DropDown from "../TicketForm/Dropdown";
import TextArea from "../TicketForm/Textarea";
import GenerateCountryList from "../utils/generateCountryList";
import GenerateStoreList from "../utils/generateStoreList";
import GenerateRoomList from "../utils/generateRoomList";
import timeHelper from "../utils/timeHelper";
import CheckBoxes from "../CommonComponents/Checkboxes/checkBoxes";
import GenerateIssueList from "../utils/GenerateIssueList";
import "./style.css";
import config from "../../main-config.json";
import PropTypes from "prop-types";

const URL = config.failures_api_url;

const TicketTemplate = ({
    data,
    calledFromPending,
    calledFromCompleted,
    isAdmin,
    resetShowTicketForm,
    updateTicketList,
    handleTicketCounterTemplate,
}) => {
    const [ticketData, setTicketData] = useState({
        ...data,
        status: calledFromPending ? "pending" : "completed",
        isDisabled: calledFromPending ? true : false,
        isSolved: !calledFromPending,
        ismounted: false,
    });

    useEffect(() => {
        setTicketData((prevState) => ({ ...prevState, ismounted: true }));

        return () => {
            setTicketData((prevState) => ({ ...prevState, ismounted: false }));
        };
    }, []);

    const handleChange = (event) => {
        const { name, value } = event.target;
        setTicketData((prevState) => ({ ...prevState, [name]: value }));

        if (name === "country") {
            let store = document.getElementsByName("store")[0].value;
            let room = document.getElementsByName("roomList")[0].value;
            setTicketData((prevState) => ({ ...prevState, store, room }));
        } else if (name === "store") {
            let room = document.getElementsByName("roomList")[0].value;
            setTicketData((prevState) => ({ ...prevState, room }));
        }
    };

    const handleCheckboxFilter = (e) => {
        const checkbox = e.target.name;
        document.querySelectorAll("input[type=checkbox]").forEach((element) => {
            element.checked = false;
        });

        document.querySelectorAll("input[type=checkbox]").forEach((element) => {
            if (element.name === checkbox) {
                element.checked = true;
                setTicketData((prevState) => ({
                    ...prevState,
                    department: checkbox,
                }));
            }
        });
    };

    const backButton = () => {
        resetShowTicketForm();
    };

    const navigateToHome = (id) => {
        if (ticketData.solution !== "") {
            handleTicketCounterTemplate(id);
        } else {
            resetShowTicketForm();
        }
    };

    const handleCheckbox = (event) => {
        const { name, value } = event.target;
        const completedDate = timeHelper();

        if (name === "solution" && value.length > 0) {
            setTicketData((prevState) => ({
                ...prevState,
                isSolved: true,
                isDisabled: false,
                [name]: value,
                status: "completed",
                completed_date: completedDate,
            }));
        } else {
            setTicketData((prevState) => ({
                ...prevState,
                isSolved: false,
                isDisabled: true,
                status: "pending",
                solution: "",
            }));
        }
    };

    const handleUpdate = async (event) => {
        event.preventDefault();

        const formData = new FormData();
        for (let entry in ticketData) {
            formData.append(entry, ticketData[entry]);
        }

        try {
            const response = await fetch(`${URL}/ticket/updateTicket.php`, {
                method: "POST",
                body: formData,
            });

            const result = await response.json();

            if (result.message === "success") {
                console.log({ ticketData });

                updateTicketList(ticketData);
                navigateToHome(ticketData.id);
            } else {
                setTicketData((prevState) => ({
                    ...prevState,
                    errorMessage: "A problem has occurred",
                }));
            }
        } catch (error) {
            setTicketData((prevState) => ({
                ...prevState,
                errorMessage: error.message,
            }));
        }
    };

    return (
        <div>
            <div
                className='backButton'
                onClick={backButton}>
                X
            </div>
            <form className='formContainer'>
                <div className='formItem'>
                    {isAdmin ? (
                        <>
                            <DropDown
                                name='country'
                                handleChange={handleChange}
                                defaultValue={ticketData.country}>
                                <GenerateCountryList />
                            </DropDown>
                            <DropDown
                                name='store'
                                handleChange={handleChange}
                                defaultValue={ticketData.store}>
                                <GenerateStoreList
                                    selectedCountry={ticketData.country}
                                />
                            </DropDown>
                            <DropDown
                                name='roomList'
                                handleChange={handleChange}
                                defaultValue={ticketData.room}>
                                {GenerateRoomList(ticketData.store)}
                            </DropDown>
                        </>
                    ) : (
                        <>
                            <DropDown name='country'>
                                <option value={ticketData.country}>
                                    {ticketData.country}
                                </option>
                            </DropDown>
                            <DropDown name='store'>
                                <option value={ticketData.store}>
                                    {ticketData.store}
                                </option>
                            </DropDown>
                            <DropDown name='roomList'>
                                <option value={ticketData.room}>
                                    {ticketData.room}
                                </option>
                            </DropDown>
                        </>
                    )}
                    <>
                        DATE:{" "}
                        <p>
                            {" "}
                            <span>{ticketData.created_date}</span>{" "}
                        </p>
                        <div id='ticketForm__checkbox'>
                            <CheckBoxes
                                type='checkbox'
                                name='electronic'
                                handleCheckboxFilter={handleCheckboxFilter}
                                isDisabled={!isAdmin}
                                defaultChecked={ticketData.department}
                            />
                            <CheckBoxes
                                name='other'
                                type='checkbox'
                                handleCheckboxFilter={handleCheckboxFilter}
                                isDisabled={!isAdmin}
                                defaultChecked={
                                    !isAdmin ? ticketData.department : undefined
                                }
                            />
                        </div>
                    </>
                </div>
                <div className='formItem-2'>
                    <div className='row'>
                        <div>
                            {ticketData.ismounted && (
                                <GenerateIssueList
                                    name='issue'
                                    handleChange={handleChange}
                                    defaultValue={ticketData.issue}
                                />
                            )}
                        </div>
                    </div>
                    <div className='row'>
                        <TextArea
                            name='report'
                            handleChange={handleChange}
                            isDisabled={!isAdmin}
                            content={ticketData.report}
                        />
                        <TextArea
                            name='cause'
                            handleChange={handleChange}
                            disabled={calledFromCompleted}
                            content={ticketData.cause}
                        />
                    </div>
                    <div className='row'>
                        <TextArea
                            name='assistant'
                            handleChange={handleChange}
                            isDisabled={!isAdmin}
                            content={ticketData.assistant}
                        />
                        <TextArea
                            name='client'
                            handleChange={handleChange}
                            isDisabled={!isAdmin}
                            content={ticketData.client}
                        />
                    </div>
                    <div className='solutionInput'>
                        <label className='label'> SOLUTION </label>
                        <input
                            autoComplete='true'
                            name='solution'
                            type='text'
                            onChange={handleCheckbox}
                            defaultValue={ticketData.solution || ""}
                            disabled={calledFromCompleted}
                        />
                        <p className='checkBoxStyle'>
                            <input
                                autoComplete='true'
                                id='test1'
                                name='isSolved'
                                type='checkbox'
                                readOnly
                                checked={ticketData.isSolved}
                                onChange={handleCheckbox}
                            />
                            <label htmlFor='test1'></label>
                        </p>
                    </div>
                    {calledFromPending && (
                        <p
                            className='submitButton'
                            disabled={ticketData.isDisabled}
                            onClick={handleUpdate}>
                            UPDATE
                        </p>
                    )}
                </div>
            </form>
        </div>
    );
};

TicketTemplate.propTypes = {
    data: PropTypes.object.isRequired,
    calledFromPending: PropTypes.bool,
    calledFromCompleted: PropTypes.bool,
    isAdmin: PropTypes.bool,
    resetShowTicketForm: PropTypes.func,
    updateTicketList: PropTypes.func,
    handleTicketCounterTemplate: PropTypes.func,
};

export default withRouter(TicketTemplate);
