export const generatePagination = (
    totalPages,
    currentPage,
    maxVisiblePages = 4
) => {
    let pages = [];
    const sidePages = Math.floor(maxVisiblePages / 2);

    pages.push(1);

    if (currentPage > sidePages + 2) {
        pages.push("...");
    }

    const startPage = Math.max(2, currentPage - sidePages);
    const endPage = Math.min(totalPages - 1, currentPage + sidePages);

    for (let i = startPage; i <= endPage; i++) {
        pages.push(i);
    }

    if (currentPage < totalPages - sidePages - 1) {
        pages.push("...");
    }

    if (totalPages > 1) {
        pages.push(totalPages);
    }

    return pages;
};
