import { useState, useEffect, useMemo } from "react";
import "../Pending/style.css";
import renderCheckBoxes from "../Helpers/renderCheckBoxes";
import SearchBar from "../CommonComponents/SearchBar";
import "../../css/cards.css";
import GenerateTicketTable from "../utils/generateTicketTable";
import filterAll from "../utils/filterAll";
import filterBy from "../utils/filterBy";
import TicketTemplate from "../TicketTemplate";
import "./style.css";
import config from "../../main-config.json";
import { generatePagination } from "../Helpers/generatePagination";

const URL = config.failures_api_url;
const CHECKBOX_QUERY_SELECTOR = "input[type='checkbox']";
const ITEMS_PER_PAGE = 15;

const Completed = () => {
    const [ticket, setTicket] = useState([]);
    const [filteredTicket, setFilteredTicket] = useState([]);
    const [groupByTicket, setGroupByTicket] = useState([]);
    const [itemData, setItemData] = useState({});
    const [search, setSearch] = useState("");
    // const [filterAllState, setFilterAllState] = useState([]);
    const [filterByState] = useState("");
    // const [checkboxes] = useState(["room", "assistant", "cause"]);
    const [department] = useState(["electronic", "other"]);
    const [showPendings, setShowPendings] = useState(true);
    const [showTicketForm, setShowTicketForm] = useState(false);
    const [hasSearched, setHasSearched] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);

    const totalPages = useMemo(
        () =>
            hasSearched
                ? Math.ceil(groupByTicket.length / ITEMS_PER_PAGE)
                : Math.ceil(filteredTicket.length / ITEMS_PER_PAGE),
        [hasSearched, groupByTicket.length, filteredTicket.length]
    );

    useEffect(() => {
        const getPendingTickets = async () => {
            try {
                const formData = new FormData();
                formData.append("status", "completed");

                const res = await fetch(
                    `${URL}/ticket/fetchPendingTickets.php`,
                    {
                        method: "POST",
                        body: formData,
                    }
                );
                const response = await res.json();
                if (response.message === "No Pending Results!") {
                    setShowPendings(false);
                }
                setTicket(response.ticket);
                let filteredTicket = filterBy(
                    response.ticket,
                    "electronic",
                    "department"
                );
                setFilteredTicket(filteredTicket);
            } catch (error) {
                console.log(error);
            }
        };

        getPendingTickets();
    }, []);

    // const handleCheckboxFilter = (event) => {
    //     const checkbox = event.target.name;
    //     const isChecked = event.target.checked;

    //     document
    //         .getElementById("groupBy")
    //         .querySelectorAll(CHECKBOX_QUERY_SELECTOR)
    //         .forEach((el) => {
    //             el.checked = false;
    //             setFilterByState("");
    //         });

    //     document
    //         .getElementById("groupBy")
    //         .querySelectorAll(CHECKBOX_QUERY_SELECTOR)
    //         .forEach((el) => {
    //             if (el.name === checkbox && el.checked !== isChecked) {
    //                 el.checked = true;
    //                 setFilterByState(checkbox);
    //             }
    //         });
    // };

    const handleDepartmentType = (event) => {
        const checkbox = event.target.name;
        document
            .getElementById("department")
            .querySelectorAll(CHECKBOX_QUERY_SELECTOR)
            .forEach((el) => {
                el.checked = false;
                if (el.name === checkbox) {
                    el.checked = true;
                    let filteredTicket = filterBy(
                        ticket,
                        el.name,
                        "department"
                    );
                    setFilteredTicket(filteredTicket);
                    setShowPendings(true);
                    setSearch("");
                    setHasSearched(false);
                    setCurrentPage(1);
                }
            });
    };

    const viewTicket = (itemData) => {
        setItemData(itemData);
        setShowTicketForm(true);
        setShowPendings(false);
        setHasSearched(false);
    };

    const updateSearch = (event) => {
        const searchTerm = event.target.value.substr(0, 20);
        setSearch(searchTerm);

        if (searchTerm !== "") {
            setHasSearched(true);
            setShowTicketForm(false);
        } else {
            setHasSearched(false);
            setShowPendings(true);
        }

        let tempSearchTickets = [];
        if (filterByState === "") {
            tempSearchTickets = filterAll(filteredTicket, searchTerm);
        } else {
            tempSearchTickets = filterBy(
                filteredTicket,
                searchTerm,
                filterByState
            );
        }
        setGroupByTicket(tempSearchTickets);
        setCurrentPage(1);
    };

    const showPendingTickets = (ticketsToMap) =>
        ticketsToMap
            .sort((a, b) => b.id - a.id)
            .slice(
                (currentPage - 1) * ITEMS_PER_PAGE,
                currentPage * ITEMS_PER_PAGE
            )
            .map((item) => (
                <GenerateTicketTable
                    key={item.id}
                    item={item}
                    viewTicket={viewTicket}
                />
            ));

    const resetShowTicketForm = () => {
        setShowTicketForm(false);
        setShowPendings(true);
    };

    const handlePageChange = (pageNumber) => {
        if (pageNumber === "...") return;
        setCurrentPage(pageNumber);
    };

    const paginationItems = useMemo(
        () => generatePagination(totalPages, currentPage),
        [totalPages, currentPage]
    );

    return (
        <div className='back'>
            {showPendings && (
                <SearchBar
                    searchInput={search}
                    updateSearch={updateSearch}
                />
            )}
            {showPendings && (
                <>
                    <div className='filterSearchContainer row-reverse'>
                        <div className='filterCheckBoxes'>
                            <h2>Department</h2>
                            <div id='department'>
                                {renderCheckBoxes(
                                    department,
                                    handleDepartmentType
                                )}
                            </div>
                            {/* <h2>Group By</h2>
                                <div id="groupBy">
                                    {renderCheckBoxes(
                                        this.state.checkboxes,
                                        this.handleCheckboxFilter.bind(this)
                                    )}
                                </div> */}
                        </div>
                        <div className='table-wrapper'>
                            <table>
                                <thead>
                                    <tr>
                                        <th>Id</th>
                                        <th>Store</th>
                                        <th>Country</th>
                                        <th>Room</th>
                                        <th>GO Name</th>
                                        <th>Completed</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {hasSearched &&
                                    groupByTicket.length >= 1 ? (
                                        showPendingTickets(groupByTicket)
                                    ) : hasSearched &&
                                      groupByTicket.length < 1 ? (
                                        <tr>
                                            <td></td>
                                            <td className='resultP'>
                                                No results found!
                                            </td>
                                        </tr>
                                    ) : (
                                        showPendingTickets(filteredTicket)
                                    )}
                                </tbody>
                            </table>
                        </div>
                    </div>

                    <div className='pagination'>
                        {paginationItems.map((page, index) => (
                            <button
                                key={index}
                                className={`${
                                    currentPage === page ? "active" : ""
                                } ${page === "..." ? "dots" : ""}`}
                                onClick={() => handlePageChange(page)}
                                disabled={
                                    page === currentPage || page === "..."
                                }>
                                {page}
                            </button>
                        ))}
                    </div>
                </>
            )}
            {showTicketForm && (
                <TicketTemplate
                    data={itemData}
                    calledFromCompleted={true}
                    resetShowTicketForm={resetShowTicketForm}
                />
            )}
        </div>
    );
};

export default Completed;
