import { useState, useEffect } from "react";
import "./style.css";
import GenerateTicket from "../utils/generateTicket";
import TicketTemplate from "../TicketTemplate/TicketTemplate";
import renderCheckBoxes from "../Helpers/renderCheckBoxes";
import "../../css/cards.css";
import filterAll from "../utils/filterAll";
import filterBy from "../utils/filterBy";
// import DropDown from "../TicketForm/Dropdown/DropDown.js";
import SearchBar from "../CommonComponents/SearchBar";
import config from "../../main-config.json";
import PropTypes from "prop-types";

const URL = config.failures_api_url;
const CHECKBOX_QUERY_SELECTOR = "input[type='checkbox']";

const Pending = ({ handleTicketCounter, pendingsStatus, profile }) => {
    const [ticket, setTicket] = useState([]);
    // const [issueTicket, setIssueTicket] = useState([]);
    const [filteredTicket, setFilteredTicket] = useState([]);
    const [groupByTicket, setGroupByTicket] = useState([]);
    const [itemData, setItemData] = useState({});
    // const [issue, setIssue] = useState("");
    const [search, setSearch] = useState("");
    const [filterByValue] = useState("");
    const [showPendings, setShowPendings] = useState(true);
    const [showTicketForm, setShowTicketForm] = useState(false);
    const [hasSearched, setHasSearched] = useState(false);
    const [deleteConfirmation, setDeleteConfirmation] = useState(false);

    // const groupBy = [
    //     "MECHANICAL ISSUE",
    //     "SOFTWARE ISSUE",
    //     "HARDWARE ISSUE",
    //     "client",
    //     "broke from customers",
    // ];

    const department = ["electronic", "other"];

    const handleDepartmentType = (event) => {
        const checkbox = event.target.name;
        const isChecked = event.target.checked;

        document
            .getElementById("department")
            .querySelectorAll(CHECKBOX_QUERY_SELECTOR)
            .forEach((el) => {
                el.checked = false;
                if (el.name === checkbox && el.checked !== isChecked) {
                    el.checked = true;
                    const filteredTicket = filterBy(
                        ticket,
                        el.name,
                        "department"
                    );
                    setFilteredTicket(filteredTicket);
                    setShowPendings(true);
                    setSearch("");
                    setHasSearched(false);
                }
            });
    };

    // const handleIssueType = (event) => {
    //     const checkbox = event.target.name;
    //     document
    //         .getElementById("checkboxes")
    //         .querySelectorAll(CHECKBOX_QUERY_SELECTOR)
    //         .forEach((el) => {
    //             el.checked = false;
    //             if (el.name === checkbox) {
    //                 el.checked = true;
    //                 const filteredTicket = filterBy(ticket, el.name, "checkboxes");
    //                 setFilteredTicket(filteredTicket);
    //                 setShowPendings(true);
    //                 setSearch("");
    //                 setHasSearched(false);
    //             }
    //         });
    // };

    const editTicket = (itemData) => {
        setItemData(itemData);
        setShowTicketForm(true);
        setShowPendings(false);
        setHasSearched(false);
    };

    const deleteTicket = (itemData) => {
        setItemData(itemData);
        setDeleteConfirmation(true);
    };

    const removeTicketFromList = (id) => {
        const newTicketList = ticket.filter((el) => el.id !== id);
        const newFilteredTicket = filteredTicket.filter((el) => el.id !== id);

        setTicket(newTicketList);
        setFilteredTicket(newFilteredTicket);

        handleTicketCounter(pendingsStatus - 1);
    };

    const submitDelete = (e) => {
        e.preventDefault();
        const { id } = itemData;

        const formData = new FormData();
        formData.append("id", id);

        fetch(`${URL}/ticket/deleteTicket.php`, {
            method: "POST",
            body: formData,
        })
            .then((res) => res.json())
            .then((data) => {
                if (data.message === "success") {
                    setDeleteConfirmation(false);
                    removeTicketFromList(id);
                }
            })
            .catch((error) => {
                console.error("Error deleting ticket:", error);
            });
    };

    const updateSearch = (event) => {
        const value = event.target.value.substr(0, 20);
        setSearch(value);

        let tempSearchTickets = [];
        setHasSearched(value !== "");

        if (filterByValue === "") {
            tempSearchTickets = filterAll(filteredTicket, value);
        } else {
            tempSearchTickets = filterBy(filteredTicket, value, filterByValue);
        }

        setShowPendings(true);
        setGroupByTicket(tempSearchTickets);
    };

    const showPendingTickets = (ticketsToMap) =>
        ticketsToMap.map((item, index) => (
            <GenerateTicket
                key={index}
                item={item}
                editTicket={item.solution === "" ? editTicket : undefined}
                deleteTicket={
                    profile === "gameoveradmin" ? deleteTicket : undefined
                }
            />
        ));

    const resetShowTicketForm = () => {
        setShowTicketForm(false);
        setShowPendings(true);
        setDeleteConfirmation(false);
    };

    const updateTicketList = (itemData) => {
        setTicket((prevTickets) =>
            prevTickets.map((ticket) =>
                ticket.id === itemData.id ? itemData : ticket
            )
        );
        setShowTicketForm(false);
        setShowPendings(true);
    };

    useEffect(() => {
        const getData = async () => {
            const formData = new FormData();
            formData.append("status", "pending");

            try {
                const res = await fetch(
                    `${URL}/ticket/fetchPendingTickets.php`,
                    {
                        method: "POST",
                        body: formData,
                    }
                );

                const response = await res.json();
                if (response.message !== "No Pending Results!") {
                    setTicket(response.ticket);
                    setShowPendings(true);
                    const filteredTicket = filterBy(
                        response.ticket,
                        "electronic",
                        "department"
                    );
                    setFilteredTicket(filteredTicket);
                    handleTicketCounter(response.ticket.length);
                } else {
                    setShowPendings(false);
                }
            } catch (error) {
                setTicket({ error: error });
            }
        };

        getData();
    }, []);

    return (
        <div className='back'>
            {showPendings && (
                <SearchBar
                    searchInput={search}
                    updateSearch={updateSearch}
                />
            )}
            {showPendings && (
                <>
                    <div className='filterSearchContainer row-reverse'>
                        <div className='filterCheckBoxes'>
                            <h2>Department</h2>
                            <div id='department'>
                                {renderCheckBoxes(
                                    department,
                                    handleDepartmentType
                                )}
                            </div>
                            {/* <div id="groupBy">
                                <h2>Issues</h2>
                                {renderCheckBoxes(groupBy, handleCheckboxFilter)}
                            </div> */}
                        </div>
                        <ul className='cards scrollable'>
                            {hasSearched && groupByTicket.length === 1 ? (
                                showPendingTickets(groupByTicket)
                            ) : hasSearched && groupByTicket.length < 1 ? (
                                <p className='resultP'> No results found! </p>
                            ) : (
                                showPendingTickets(filteredTicket)
                            )}
                        </ul>
                    </div>
                </>
            )}
            {showTicketForm && (
                <TicketTemplate
                    data={itemData}
                    calledFromPending={true}
                    resetShowTicketForm={resetShowTicketForm}
                    handleTicketCounterTemplate={removeTicketFromList}
                    isAdmin={profile === "gameoveradmin"}
                    updateTicketList={updateTicketList}
                />
            )}
            {deleteConfirmation && (
                <>
                    <div className='deletePopupContainer'>
                        <p>
                            Are you sure you want to delete this ticket? <br />
                            <small>{"YOU CAN'T UNDO THIS ACTION!"}</small>
                        </p>
                        <div
                            id='options'
                            onClick={submitDelete}>
                            YES!
                        </div>
                        <div
                            id='options'
                            onClick={resetShowTicketForm}>
                            NO!
                        </div>
                    </div>
                    <div className='popupOverlay'></div>
                </>
            )}
        </div>
    );
};

Pending.propTypes = {
    handleTicketCounter: PropTypes.func,
    pendingsStatus: PropTypes.number,
    profile: PropTypes.string,
};

export default Pending;
