import { useState, useEffect } from "react";
import "../../css/issue.css";
import config from "../../main-config.json";
import PropTypes from "prop-types";
const URL = config.failures_api_url;

const GenerateIssueList = ({ defaultValue, handleChange }) => {
    const [data, setData] = useState(["default"]);

    const getData = async () => {
        return fetch(`${URL}/ticket/issues/get.php`, {
            method: "GET",
            mode: "cors",
        })
            .then((response) => response.json())
            .then((iss) => {
                setData(iss);
                return iss;
            })
            .catch((error) => {
                setData({ error: error });
            });
    };
    const handleIssue = (args) => handleChange(args);

    useEffect(() => {
        getData();
        return () => {
            setData([]);
        };
    }, []);

    return (
        <>
            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-start",
                }}>
                <label id='issue'>*Issues</label>
                <select
                    name='issue'
                    className='issuelist'
                    onChange={handleIssue}
                    value={defaultValue || " "}>
                    {Object.values(data).map((item, index) => {
                        return <option key={index}>{item.issue}</option>;
                    })}
                </select>
            </div>
        </>
    );
};

GenerateIssueList.propTypes = {
    defaultValue: PropTypes.string,
    handleChange: PropTypes.func.isRequired,
};

export default GenerateIssueList;
